.donate-button img {
  border: none;
  box-shadow: none;
  height: 34px;
  margin-bottom: 1px;
  vertical-align: middle;
  width: 35px;
}

.donate-button {
  background-color: white;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  bottom: 1.2rem;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  color: #222;
  display: inline-flex;
  font-family: 'Cookie', cursive;
  font-size: 28px;
  height: 51px;
  letter-spacing: 0.6px;
  line-height: 35px;
  margin: 0 auto;
  overflow: hidden;
  padding: 7px 8px;
  position: fixed;
  right: 1.6rem;
  text-decoration: none;
  transition: box-shadow 0.2s 0.5s, width 0.5s;
  width: 45px;
  z-index: 98;
}

.donate-button.donate-button-wide {
  width: 200px;
}

.donate-button:hover, .donate-button:active, .donate-button:focus  {
  box-shadow: 0px 1px 2px 2px rgba(0, 0, 0, 0.1);
  color: black;
  text-decoration: none;
  width: 200px;
}

.donate-button span {
  left: 46px;
  position: absolute;
}
