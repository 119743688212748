#peg-board {
  background-color: rgb(255, 243, 227);
  background-image: radial-gradient(circle at 0.5rem 0.5rem, rgb(207, 144, 89) 0.5rem, transparent 0);
  background-position: 2.4rem 2.0rem;
  background-size: 4.3rem 4.3rem;
  height: 200%;
  position: relative;
  width: 200%;
}

#peg-holder {
  background-color: rgb(255, 243, 227);
  border-bottom: 4px solid rgb(207, 144, 89);
  border-right: 4px solid rgb(207, 144, 89);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  height: 5.5rem;
  width: 17.5rem;
}
