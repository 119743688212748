.react-draggable img {
  padding: 0.4rem;
}
.react-draggable {
  z-index: 1;
}
.react-draggable-dragged {
  z-index: 2;
}
.react-draggable-dragging {
  z-index: 99;
}

#stamp-game {
  height: 200%;
}

#stamp-game__box {
  align-items: stretch;
  border: 4px solid rgb(137, 95, 43);
  display: grid;
  grid-template-areas: "skittle skittle circle circle";
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 5fr 6fr;
  justify-items: stretch;
  margin: auto;
  max-width: 570px;
  width: 100%;
}
@media (min-width: 650px) {
  #stamp-game__box {
    border-width: 5px;
    width: 80%;
  }
}

#stamp-game__box > div {
  align-items: center;
  background: #fff3e1;
  border: 4px solid rgb(137, 95, 43);
  display: flex;
  justify-content: space-around;
  padding: 0.4rem;
}
@media (min-width: 570px) {
  #stamp-game__box > div {
    border-width: 5px;
    padding: 1.2rem;
  }
}

#stamp-game .moved {
  position: absolute;
}

#stamp-game__counters {
  grid-area: circle;
}
#stamp-game__counters > div {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}
#stamp-game__counters .react-draggable {
  max-width: 50px;
}
@media (min-width: 570px) {
  #stamp-game__counters .react-draggable {
    max-width: 80px;
  }
}

#stamp-game__skittles {
  grid-area: skittle;
}
#stamp-game__skittles > div {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}
#stamp-game__skittles .react-draggable {
  max-width: 40px;
}
@media (min-width: 570px) {
  #stamp-game__skittles .react-draggable {
    max-width: 55px;
  }
}

.stamp-game__stamps img {
  width: 50px;
}
@media (min-width: 570px) {
  .stamp-game__stamps img {
    width: 80px;
  }
}

