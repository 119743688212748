#home-page {
  margin: 0 auto;
  max-width: 660px;
  padding: 2rem 3rem;
}

.materials {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin: 1rem -1.25rem;
}

.material {
  color: inherit;
  padding: 1.25rem;
  text-decoration: none;
  width: 50%;
}

.material__card {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  color: inherit;
  font-family: 'Cookie', cursive;
  font-size: 3rem;
  overflow: hidden;
  text-align: center;
}

.material__card__golden-beads,
.material__card__peg-board,
.material__card__stamp-game {
  padding-bottom: 100%;
  position: relative;
  transition: all 0.2s ease-in-out;
  z-index: 1;
}

.material:hover .material__card__golden-beads,
.material:hover .material__card__peg-board,
.material:hover .material__card__stamp-game {
  opacity: 0.8;
  transform: scale(1.05);
}

.material__card__golden-beads {
  background: no-repeat url(../images/beads/golden-beads-screenshot.png) top left/cover;
}

.material__card__peg-board {
  background: no-repeat url(../images/peg-board/peg-board-screenshot.png) top left/cover;
}

.material__card__stamp-game {
  background: no-repeat url(../images/stamp-game/stamp-game-screenshot.png) center/80%;
}

.material__card__caption {
  background: white;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  display: block;
  position: relative;
  z-index: 2;
}
