* {
  box-sizing: border-box;
}

html {
  font-size: 10px;
}

html,
body,
#root {
  height: 100%;
}

body {
  color: #444;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 1.8rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.6;
  margin: 0;
}

img {
  display: block;
  max-width: 100%;
  pointer-events: none;
  user-select: none;
}

h1 {
  font-size: 3.4rem;
}

.signature {
  font-family: 'Cookie', cursive;
  font-size: 3.4rem;
}
