#controls {
  bottom: 0;
  display: flex;
  left: 0;
  padding: 0 1rem;
  position: fixed;
  z-index: 98;
}
@media (min-width: 650px) {
  #controls {
    bottom: auto;
    flex-direction: column;
    left: auto;
    right: 0;
    top: 1.2rem;
  }
}

#home-button,
#reset-button {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  display: block;
  height: 4.8rem;
  margin: 0 0.6rem 1rem;
  text-decoration: none;
  transition: box-shadow 0.2s;
  width: 4.8rem;
}

#home-button {
  background: no-repeat white url(./images/home-black-18dp.svg) center/70%;
}

#reset-button {
  background: no-repeat white url(./images/refresh-black-18dp.svg) center/70%;
}

#reset-button:hover {
  box-shadow: 0px 1px 3px 2px rgba(0, 0, 0, 0.1);
}
