#golden-beads {
  height: 200%;
}

#golden-beads__holder {
  background: #fff3e1;
  border: 4px solid rgb(137, 95, 43);
  display: grid;
  grid-template-columns: 3fr 3fr 2fr 2fr;
  height: 15rem;
  margin: 0 auto;
  max-width: 50rem;
}

#golden-beads__holder > div {
  align-items: center;
  border: 4px solid rgb(137, 95, 43);
  display: flex;
  justify-content: center;
  pointer-events: none;
}

.golden-beads {
  --default-width: 9rem;
  opacity: 0.9;
  pointer-events: auto;
  position: absolute;
  transition: opacity 0.2s, width 0.15s;
  width: var(--default-width);
}
.golden-beads.moved {
  opacity: 1;
}
@media (min-width: 50rem) {
  .golden-beads.moved {
    --default-width: 15vmin;
  }
}

.golden-beads-1 {
  background: no-repeat url(../images/beads/golden-bead-1.png) center/calc(0.17 * var(--default-width)) calc(0.0782 * var(--default-width));
  height: 5rem;
  width: 5rem;
}

.golden-beads-10 {
  background: no-repeat url(../images/beads/golden-bead-10.png) center/contain;
  padding-bottom: calc(0.87 * var(--default-width));
  width: calc(0.2 * var(--default-width));
}

.golden-beads-100 {
  background: no-repeat url(../images/beads/golden-bead-100.png) center/contain;
  padding-bottom: calc(0.774 * var(--default-width));
  width: calc(0.835 * var(--default-width));
}

.golden-beads-1000 {
  background: no-repeat url(../images/beads/golden-bead-1000.png) center/contain;
  padding-bottom: calc(0.94 * var(--default-width));
}
